import "./ToastMessage.css";
import {useEffect} from "react";

const ToastMessage = ({ text, duration, show, onClose, position = 'top' }) => {

    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                onClose();
            }, duration);
            return () => clearTimeout(timer);
        }
    }, [show, duration, onClose]);

    return (
        <>
            <div className={`toast-message-container ${position}  ${show ? 'show' : 'hide'}`}>
                <p className="toast-message">{text}</p>
                <button className="toast-button" onClick={onClose}>&times;</button>
            </div>
        </>
    );
}

export default ToastMessage;
