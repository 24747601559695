import {fetchMarkAsReadNews, fetchNews, fetchUserTasks, fetchValidateUserTaskById} from '../api/taskActions';
import {setUserTotalPoints} from "../../User/model/userActions";

// Action Types
export const SET_TASKS = 'SET_TASKS';
export const SET_NEWS = 'SET_NEWS';
export const SET_DAILY_CODE_TASK_STATUS = 'SET_DAILY_CODE_TASK_STATUS';
export const SET_TASK_STATUS = 'SET_TASK_STATUS';
export const UPDATE_TASKS = 'UPDATE_TASKS';
export const UPDATE_NEWS = 'UPDATE_NEWS';

const UPDATE_IS_READ = 'UPDATE_IS_READ';

// Action Creators
export const setTasks = (tasks) => ({
    type: SET_TASKS,
    payload: tasks,
});

export const setNews = (news) => ({
    type: SET_NEWS,
    payload: news
})
export const updateTasks = (tasks) => ({
    type: UPDATE_TASKS,
    payload: tasks
})

export const updateNews = (news) => ({
    type: UPDATE_NEWS,
    payload: news
})

export const setDailyCodeTaskStatus = (status) => {
    console.log("setDailyCodeTaskStatus", status);
    return {
       type: SET_DAILY_CODE_TASK_STATUS,
       payload: status
    };
};

export const setTaskStatus = (taskId, status) => {
    console.log(`setTaskStatus: ${taskId} Status ${status}`);
    return {
        type: SET_TASK_STATUS,
        payload: {
            taskId,
            status
        }
    };
};

const updateIsRead = (id) => {
    console.log(`updateIsRead id: ${id}`);
    return {
        type: UPDATE_IS_READ,
        payload: id
    };
};

export const getUserTasks = (page, size) => async (dispatch) => {
    try {
        const response = await fetchUserTasks(page, size);
        console.log("getUserTasks response: ", response);
        if (response) {
            const tasks = {
                daily_check_codes: response.content.filter(item => item.type === 'DAILY_CHECK_CODE'),
                tasks: response.content.filter(item => item.type !== 'DAILY_CHECK_CODE'),
                empty: response.empty,
                first: response.first,
                last: response.last,
                number: response.number,
                totalElements: response.totalElements,
                totalPages: response.totalPages
            };
            dispatch(setTasks(tasks));
        }
    } catch (error) {
        console.error('Failed to fetch tasks', error);
    }
};

export const addMoreUserTasks = (page, size, token) => async (dispatch) => {
    try {
        const response = await fetchUserTasks(page, size, token);
        console.log("loadMoreTasks response: ", response);
        if (response) {
            const dailyCheckCodes = response.content.some(item => item.type === 'DAILY_CHECK_CODE')
                ? response.content.filter(item => item.type === 'DAILY_CHECK_CODE')
                : [];
            const newTasks = {
                daily_check_codes: dailyCheckCodes,
                tasks: response.content.filter(item => item.type !== 'DAILY_CHECK_CODE'),
                empty: response.empty,
                first: response.first,
                last: response.last,
                number: response.number,
                totalElements: response.totalElements,
                totalPages: response.totalPages
            };

            // Dispatch the merged tasks to update the store
            dispatch(updateTasks(newTasks));
        }
    } catch (error) {
        console.error('Failed to fetch new tasks', error);
    }
}


export const validateUserTaskById = (taskId, token, code) => async (dispatch) => {
    try {
        const response = await fetchValidateUserTaskById(taskId, token, code);
        console.log("validateUserTaskById response: ", response);
        if (response) {
            console.log("validateUserTaskById total-points", response['total-points']);
            await dispatch(setUserTotalPoints(response['total-points']));
            return true;
        }
        return false;
    } catch (error) {
        console.error('Failed to fetch validate user task by id', error);
        return false;
    }
}

export const markAsReadNews = (newsId) => async (dispatch) => {
    try {
        const response = await fetchMarkAsReadNews(newsId);
        console.log("markAsReadNews response", response);
        if (response) {
            console.log("markAsReadNews total-points", response['total-points']);
            await dispatch(setUserTotalPoints(response['total-points']));
            await dispatch(updateIsRead(newsId));
            return true;
        }
        return false;
    } catch (e) {
        console.error('Failed to mark as read news', e);
        return false;
    }
}

export const getNews = (page, size) => async (dispatch) => {
    try {
        const response = await fetchNews(page, size);
        console.log("getNews", response);
        if (response) {
            await dispatch(setNews(response));
        }
    } catch (e) {
        console.error("Failed to get News: ", e);
        throw e;
    }
}

export const getMoreNews = (page, size) => async (dispatch) => {
    try {
        const response = await fetchNews(page, size);
        console.log("getMoreNews: ", response);
        if (response) {
            dispatch(updateNews(response));
        }
    } catch (e) {
        console.error("Failed to get more News: ", e);
        throw e;
    }
}


