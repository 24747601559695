import React, {useEffect, useState} from "react";
import NewsItem from "./NewsItem";
import {useDispatch, useSelector} from "react-redux";
import {
    selectNews
} from "../model/taskSelectors";
import {getMoreNews} from "../model/taskActions";
import {translate} from "../../../shared/utils/translator";
import CMLogoFrens from "../../../shared/assets/images/Logo Small.jpg";

const NewsList = () => {
    const dispatch = useDispatch();
    const [newsIsNotRead, setNewsIsNotRead] = useState(0);
    ///// TEST
    // const news = {
    //         "content": [
    //             {
    //                 "id": 111,
    //                 "title": "Collective Money",
    //                 "author": "474703892",
    //                 "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //                 "link": "https://t.me/collective_en/148",
    //                 "locale": "en",
    //                 "image-link": "https://images.wallpaperscraft.com/image/single/cherry_bloom_spring_124733_1280x800.jpg",
    //                 "expired-date": "2024-10-23T23:00:00.238",
    //                 "created-at": "2024-10-13T10:47:28.176783",
    //                 "is-read": false
    //             },
    //             {
    //                 "id": 112,
    //                 "title": "Collective Money",
    //                 "author": "474703892",
    //                 "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //                 "link": "https://t.me/collective_en/154",
    //                 "locale": "en",
    //                 "image-link": "https://images.wallpaperscraft.com/image/single/cherry_bloom_spring_124733_1280x800.jpg",
    //                 "expired-date": "2024-10-23T23:00:00.238",
    //                 "created-at": "2024-10-13T11:03:04.155266",
    //                 "is-read": false
    //             },
    //             {
    //                 "id": 113,
    //                 "title": "Collective Money",
    //                 "author": "474703892",
    //                 "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //                 "link": "https://t.me/collective_en/155",
    //                 "locale": "en",
    //                 "image-link": "https://images.wallpaperscraft.com/image/single/cherry_bloom_spring_124733_1280x800.jpg",
    //                 "expired-date": "2024-10-23T23:00:00.238",
    //                 "created-at": "2024-10-13T11:04:07.725907",
    //                 "is-read": false
    //             },
    //             {
    //                 "id": 114,
    //                 "title": "Collective Money",
    //                 "author": "474703892",
    //                 "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //                 "link": "https://t.me/collective_ru/298",
    //                 "locale": "en",
    //                 "image-link": "https://images.wallpaperscraft.com/image/single/cherry_bloom_spring_124733_1280x800.jpg",
    //                 "expired-date": "2024-10-23T23:00:00.238",
    //                 "created-at": "2024-10-13T11:04:24.754499",
    //                 "is-read": false
    //             },
    //             {
    //                 "id": 115,
    //                 "title": "Collective Money",
    //                 "author": "474703892",
    //                 "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //                 "link": "https://t.me/collective_ru/294",
    //                 "locale": "en",
    //                 "image-link": "https://images.wallpaperscraft.com/image/single/cherry_bloom_spring_124733_1280x800.jpg",
    //                 "expired-date": "2024-10-23T23:00:00.238",
    //                 "created-at": "2024-10-13T11:04:41.594872",
    //                 "is-read": false
    //             },
    //             {
    //                 "id": 116,
    //                 "title": "Collective Money",
    //                 "author": "474703892",
    //                 "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //                 "link": "https://t.me/collective_ru/292",
    //                 "locale": "en",
    //                 "image-link": "https://images.wallpaperscraft.com/image/single/cherry_bloom_spring_124733_1280x800.jpg",
    //                 "expired-date": "2024-10-23T23:00:00.238",
    //                 "created-at": "2024-10-13T11:04:55.676611",
    //                 "is-read": false
    //             },
    //         ],
    //         "pageable": {
    //             "pageNumber": 0,
    //             "pageSize": 20,
    //             "sort": {
    //                 "empty": true,
    //                 "sorted": false,
    //                 "unsorted": true
    //             },
    //             "offset": 0,
    //             "paged": true,
    //             "unpaged": false
    //         },
    //         "totalElements": 7,
    //         "totalPages": 1,
    //         "last": true,
    //         "size": 20,
    //         "number": 0,
    //         "sort": {
    //             "empty": true,
    //             "sorted": false,
    //             "unsorted": true
    //         },
    //         "numberOfElements": 7,
    //         "first": true,
    //         "empty": false
    //     };
    ///// TEST
    const news = useSelector(selectNews);

    // const [loading, setLoading] = useState(false);
    //
    // useEffect(() => {
    //     const fetchNewsData = async () => {
    //         if (!news || news.content.length === 0) {
    //             setLoading(true);  // Start loading
    //             await dispatch(getNews(0, 20));
    //             setLoading(false); // End loading whether success or failure
    //         }
    //     };
    //
    //     fetchNewsData();
    // }, [dispatch]);
    //
    // if (loading) {
    //     return (
    //         <LoadingSpinner />
    //     );
    // }

    // if (!news || news.content.length === 0) {
    //     return (
    //         <div className="news-list-empty">
    //             <h1 className="news-title">{translate("news-title-news-page")}</h1>
    //             <div className="news-logo-section">
    //                 <img src={CMLogoFrens} alt="CM_tasks_Logo" className="cm_tasks_logo" />
    //             </div>
    //             <p className="reward-text">{translate("news-list-empty")}</p>
    //         </div>
    //     )
    // }
    // console.log("News: ", news);
    const handleLoadMoreNews = () => {
        if (!news.last && news.number <= news.totalPages) {
            dispatch(getMoreNews(news.number + 1, 20));
        }
    }

    useEffect(() => {
        console.log(news);
        if (news.content.length > 0) {
            setNewsIsNotRead(news.content.filter(item => item["is-read"] === false).length);
        }
    }, [news]);

    return (
      <div className="news-list">
          {
              !news || news.content.length === 0
                  ?
                  <>
                      <h1 className="news-title">{translate("news-title-news-page")}</h1>
                      <div className="news-logo-section">
                          <img src={CMLogoFrens} alt="CM_tasks_Logo" className="cm_tasks_logo" />
                      </div>
                      <p className="reward-text">{translate("news-list-empty")}</p>
                  </>
                  :
                  <>
                      <h1 className="news-title">{translate("news-title-news-page")} {newsIsNotRead}</h1>
                      {news.content.map((item, index) => (
                          <div className="news-item" key={index}>
                              <NewsItem news={item} index={index}/>
                          </div>
                      ))}
                      {
                          !news.last && (
                              <button className="load-more-news" onClick={handleLoadMoreNews}>
                                  {translate("load-more-news")}
                              </button>
                          )
                      }
                  </>
          }
          {/*<h1 className="news-title">{translate("news-title-news-page")} {news.totalElements}</h1>*/}
          {/*{news.content.map((item, index) => (*/}
          {/*    <div className="news-item" key={index}>*/}
          {/*        <NewsItem news={item} index={index}/>*/}
          {/*    </div>*/}
          {/*))}*/}
          {/*{*/}
          {/*    !news.last && (*/}
          {/*        <button className="load-more-news" onClick={handleLoadMoreNews}>*/}
          {/*            {translate("load-more-news")}*/}
          {/*        </button>*/}
          {/*    )*/}
          {/*}*/}
      </div>
    );
}
export default NewsList;
