import "./ModalWindow.css";
import {translate} from "../utils/translator";
const ModalWindow = ({content, closeModal}) => {
    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="close-modal-btn" onClick={closeModal}>
                    &times;
                </button>
                <p>{content}</p>
                <button className="understand-modal-btn" onClick={closeModal}>{translate("modal-window-button")}</button>
            </div>
        </div>
    )
}

export default ModalWindow;
