import BottomNavigationBar from "../../shared/components/BottomNavigationBar/BottomNavigationBar";
import HomePage from "../../pages/HomePage";
import FrensPage from "../../pages/FrensPage";
import QuestPage from "../../pages/QuestPage";
import TaskPage from "../../pages/TasksPage";
import NewsPage from "../../pages/NewsPage";
import Header from "../../shared/components/Header/Header";
import React, {useEffect, useState} from "react";
import StatisticsPage from "../../pages/StatisticsPage";
import SettingsPage from "../../pages/SettingsPage";
import useTelegram from "../../shared/hooks/useTelegram";
const AppRoutes = () => {
    const {backButton} = useTelegram();
    const [currentPage, setCurrentPage] = useState('');

    const goToPage = (page) => setCurrentPage(page);

    const pageComponents = {
        '': <HomePage />,
        'Tasks': <TaskPage />,
        'Quest': <QuestPage />,
        'Frens': <FrensPage />,
        'News': <NewsPage />,
        'Stats': <StatisticsPage />,
        'Settings': <SettingsPage />,
    };

    const CurrentPageComponent = pageComponents[currentPage] || null;

    useEffect(() => {
        if (currentPage === "Stats" || currentPage === "Settings") {
            backButton.show();
        }

        return () => {
            backButton.hide();
        }
    }, [currentPage]);

    useEffect(() => {
        backButton.onClick(() => {
            goToPage('');
        });

        return () => backButton.offClick(() => {
            goToPage('');
        })
    }, []);

    return (
        <>
            {currentPage !== "Stats" && currentPage !== "Settings" &&
                <Header goToPage={goToPage} />
            }

            {CurrentPageComponent}
            {currentPage !== "Stats" && currentPage !== "Settings" &&
                <BottomNavigationBar goToPage={goToPage} />
            }
        </>
    )
}

export default AppRoutes;
