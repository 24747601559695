import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {selectUserLocale, selectUserTimezone} from "../../entities/User/model/userSelectors";
import {useState} from "react";
import ToastMessage from "../../shared/components/ToastMessage";
import {setLanguage, translate} from "../../shared/utils/translator";
import {setUserLanguage} from "../../entities/User/model/userActions";
const SettingsPage = () => {
    const dispatch = useDispatch();
    // const nickname = useSelector(selectUserName);
    const timeZone = useSelector(selectUserTimezone);
    const locale = useSelector(selectUserLocale);
    const [selectedLanguage, setSelectedLanguage] = useState(locale);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const handleShowToast = (message = translate("in-development")) => {
        setToastMessage(message);
        setShowToast(true);
    };

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleChangeLanguage = (language) => {
        dispatch(setUserLanguage(language));
        setLanguage(language);
        setSelectedLanguage(language);
    };

    return (
        <section className="settings-page-container no-scrollbar">
            <h1 className="settings-title">{translate("settings-title")}</h1>
            <div className="time-settings-container">
                <div className="language-settings-left-column">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                </svg>
                <p>{translate("your-locale-timezone")}</p>
                </div>
                <h4>{timeZone}</h4>
            </div>
            <div className="language-settings-container">
                <div className="language-settings-left-column">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-globe" viewBox="0 0 16 16">
                    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z"/>
                </svg>
                <p>{translate("settings-language")}</p>
                </div>
                <h4>
                    <span className={`language-option ${selectedLanguage === "ru" && "selected-language"}`} onClick={() => handleChangeLanguage("ru")}>
                        ru
                    </span>
                    &nbsp;
                    <span className={`language-option ${selectedLanguage === "en" && "selected-language"}`} onClick={() => handleChangeLanguage("en")}>
                        en
                    </span>
                </h4>
            </div>
            <div className="buttons-container">
                <button className="change-telegram-account" onClick={() => handleShowToast()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-telegram" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
                    </svg>
                    <p>{translate("change-telegram-account")}</p>
                </button>
                <button className="connect-x-button" onClick={() => handleShowToast()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" className="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                    <p>{translate("settings-connect-x")}</p>
                </button>
                <button className="connect-discord-button" onClick={() => handleShowToast()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" className="bi bi-discord" viewBox="0 0 16 16">
                        <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                    </svg>
                    <p>{translate("settings-connect-discord")}</p>
                </button>
                <button className="connect-ton-button" onClick={() => handleShowToast()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" className="bi bi-wallet" viewBox="0 0 16 16">
                        <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1"/>
                    </svg>
                    <p>{translate("settings-connect-ton-wallet")}</p>
                </button>
                <button className="connect-env-button" onClick={() => handleShowToast()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path d="M18 34.875C27.3198 34.875 34.875 27.3198 34.875 18C34.875 8.6802 27.3198 1.125 18 1.125C8.6802 1.125 1.125 8.6802 1.125 18C1.125 27.3198 8.6802 34.875 18 34.875Z" fill="#627EEA"/>
                        <path d="M18 2V13.6134L27 18L18 2Z" fill="white" fillOpacity="0.602"/>
                        <path d="M18 2L9 18L18 13.6134V2Z" fill="white"/>
                        <path d="M18 24.8702V33L27 19L18 24.8702Z" fill="white" fillOpacity="0.602"/>
                        <path d="M18 33V24.8702L9 19L18 33Z" fill="white"/>
                        <path d="M18 23L27 17.9148L18 14V23Z" fill="white" fillOpacity="0.2"/>
                        <path d="M9 17.9148L18 23V14L9 17.9148Z" fill="white" fillOpacity="0.602"/>
                    </svg>
                    <p>{translate("settings-connect-ethereum")}</p>
                </button>
                <button className="connect-youtube-button" onClick={() => handleShowToast()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" className="bi bi-youtube" viewBox="0 0 16 16">
                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                    </svg>
                    <p>{translate("settings-connect-youtube")}</p>
                </button>
            </div>
            {/*<button className="settings-exit" onClick={() => onClose()}>*/}
            {/*    {translate("settings-exit")}*/}
            {/*</button>*/}
            <ToastMessage
                text={toastMessage}
                duration={3000}
                show={showToast}
                onClose={handleCloseToast} />
        </section>
    )
}

export default SettingsPage;
