import axiosInstance from "../../../shared/utils/api";

export const fetchCurrentUser = async () => {
    try {
        const response = await axiosInstance.get(`/users/current`);
        console.log('fetchCurrentUser:', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching current user:', error);
        return null;
    }
};

export const fetchStatistics = async () => {
    try {
        const response = await axiosInstance.get('/users/statistics');
        console.log('fetchStatistics: ', response.data.data);
        return response.data.data;
    } catch (e) {
        console.error("Failed to fetch statistics: ", e);
        throw e;
    }
}
export const fetchAuthUser = async (userData, invite) => {
    try {
        const postData = {
            'tg-data': userData,
        };

        if (invite) {
            postData['invite'] = invite;
        }

        const response = await axiosInstance.post(`/public/authentication`, postData, { skipAuth: true });

        if (response.status === 204) {
            console.log('No content returned.');
            return null;
        }

        const authHeader = response.headers['authorization'];
        console.log('Authorization Header:', authHeader);
        return authHeader;
    } catch (error) {
        console.error('Error fetchAuthUser:', error);
        return null;
    }
};

export const fetchLanguage = async (language) => {
    try {
        const response = await axiosInstance.post(`/users/change-language?locale=${language.toUpperCase()}`);
        console.log('fetchLanguage: ', response.data.data);
        return response.data.data;
    } catch (e) {
        console.error('Error fetchLanguage: ', e);
        return null;
    }
}
