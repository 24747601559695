import enTranslations from '../config/locales/en/translation.json';
import ruTranslations from '../config/locales/ru/translation.json';

const translations = {
    en: enTranslations,
    ru: ruTranslations,
};

let currentLanguage = 'en';

export const setLanguage = (language) => {
    console.log("setLanguage", currentLanguage);
    if (language !== 'ru') {
        currentLanguage = 'en';
    } else {
        currentLanguage = 'ru';
    }
};

export const translate = (key, params = {}) => {
    let translation = translations[currentLanguage][key] || key;

    Object.keys(params).forEach((param) => {
        const regex = new RegExp(`{{${param}}}`, 'g');
        translation = translation.replace(regex, params[param]);
        // translation = translation.replace(`{{${param}}}`, params[param]);
    });

    return translation;
};
