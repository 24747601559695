import "./Modal.css";
import React, {useEffect, useState} from "react";
import {translate} from "../../../shared/utils/translator";
import {markAsReadNews} from "../../../entities/Task/model/taskActions";
import {useDispatch} from "react-redux";
function convertToEmbedURL(youtubeUrl) {
    // Regex to extract the video ID from various YouTube URL formats
    const regex = /(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be\.com\/(?:watch\?v=|embed\/|v\/|.*v=))([^&?\/\s]{11})/;

    // Extract video ID using the regex
    const match = youtubeUrl.match(regex);
    const videoId = match ? match[1] : null;

    if (videoId) {
        // Construct the embeddable URL with autoplay and mute
        return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&controls=0`;
    } else {
        // Return an error or placeholder if the URL is invalid
        return null;
    }
}
const Modal = ({ id, show, onClose, videoLink, timeTillEnd, updateUserStatus }) => {
    const dispatch = useDispatch();
    const [timeLeft, setTimeLeft] = useState(timeTillEnd);
    const [autoplayLink, setAutoplayLink] = useState("");

    useEffect(() => {
        let intervalId;

        if (show) {
            const autoplayUrl = convertToEmbedURL(videoLink);
            setAutoplayLink(autoplayUrl);
            // Reset timer when the modal is opened
            setTimeLeft(timeTillEnd);

            // Start the timer only when the modal is visible
            intervalId = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 0) {
                        clearInterval(intervalId); // Stop the timer at 00:00
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }

        // Clear interval when modal is closed or time is up
        return () => clearInterval(intervalId);
    }, [show, timeTillEnd]);

    // Format time as MM:SS
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleClick = () => {
        updateUserStatus("PENDING");
        const markAsReadResponse = dispatch(markAsReadNews(id))
        if (markAsReadResponse) {
            updateUserStatus("DONE");
        }
        onClose();
    };

    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-window-news" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <div className="modal-content-news">
                    {
                        timeLeft > 0
                            ?
                            <h2>{formatTime(timeLeft)}</h2>
                            :
                            <button className="action-button" onClick={handleClick}>
                                {translate("reward-text-code-section-home-page")}
                            </button>
                    }
                    <iframe className="modal-video-player" src={autoplayLink} allow="autoplay"></iframe>
                </div>
            </div>
        </div>
    );
};
export default Modal;
