import React from 'react';
import {
    selectReferrals
} from '../model/referralSelectors';
import {useDispatch, useSelector} from "react-redux";
import "./style.css";
import ReferralItem from "./ReferralItem";
import {getUserReferrals} from "../model/referralActions";
import {translate} from "../../../shared/utils/translator";

const ReferralList = () => {
    const dispatch = useDispatch();
    const referrals = useSelector(selectReferrals);

    // const [loading, setLoading] = useState(false);
    //
    // useEffect(() => {
    //     const fetchReferralsData = async () => {
    //         if (!referrals || referrals.content.length === 0) {
    //             setLoading(true);  // Start loading
    //             await dispatch(getUserReferrals(0, 20));
    //             setLoading(false); // End loading whether success or failure
    //         }
    //     };
    //
    //     fetchReferralsData();
    // }, [dispatch]);

    // if (loading) {
    //     return (
    //         <LoadingSpinner />
    //     );
    // }

    // const referrals = {
    //     content: [
    //         {
    //             nickname: "Test",
    //             points: 100000
    //         },
    //         {
    //             nickname: "Test",
    //             points: 100000
    //         },
    //         {
    //             nickname: "Test",
    //             points: 100000
    //         },
    //         {
    //             nickname: "Test",
    //             points: 100000
    //         },
    //         {
    //             nickname: "Test",
    //             points: 100000
    //         },
    //         {
    //             nickname: "Test",
    //             points: 100000
    //         },
    //         {
    //             nickname: "Test",
    //             points: 100000
    //         },
    //         {
    //             nickname: "Test",
    //             points: 100000
    //         },
    //         {
    //             nickname: "Test",
    //             points: 100000
    //         },
    //         {
    //             nickname: "Test",
    //             points: 100000
    //         },
    //         {
    //             nickname: "Test",
    //             points: 100000
    //         },
    //     ]
    // }

    if (!referrals || referrals.content.length === 0) {
        return (
            <div className="referral-list-empty">
                {translate("referral-list-empty")}
                {/*<div className="news-logo-section">*/}
                {/*    <img src={CMLogoFrens} alt="CM_tasks_Logo" className="cm_tasks_logo" />*/}
                {/*</div>*/}
                {/*<p className="reward-text">{translate("news-list-empty")}</p>*/}
            </div>
        )
    }

    const requestMoreReferrals = () => {
        if (!referrals.last) {
            // setLoading(true);
            dispatch(getUserReferrals(referrals.number + 1, 20));
            // setLoading(false);
        }
    };

    return (
        <div className="referral-list">
            {referrals.content.map((referral, index) => {
                return (
                    <div className="referral-item" key={index}>
                        <ReferralItem referral={referral} />
                    </div>
                );
            })}
            {
                !referrals.last && <button className="add-more-referrals-button" onClick={requestMoreReferrals}>{translate("see-more-your-frens")}</button>
            }
        </div>
    );
};

export default ReferralList;
