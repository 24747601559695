import React, { useState, useEffect } from 'react';
import { ReactComponent as TimerIcon } from '../../../shared/assets/images/timer.svg'; // Import the SVG as a React component

const DayTimer = ({openModal}) => {
    const [timeRemaining, setTimeRemaining] = useState('');

    const calculateTimeRemaining = () => {
        const now = new Date();

        // Get the current time in UTC
        const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60 * 1000);

        // Convert the current time in UTC to UTC+3
        const utc3Offset = 3 * 60 * 60 * 1000; // 3 hours in milliseconds
        const utc3Time = new Date(utcNow.getTime() + utc3Offset);

        // Calculate 12:00 AM (midnight) for the next day in UTC+3
        const midnight = new Date(utc3Time.getUTCFullYear(), utc3Time.getUTCMonth(), utc3Time.getUTCDate(), 0, 0, 0);

        // If current time is past midnight, calculate time till the next midnight
        if (utc3Time >= midnight) {
            midnight.setUTCDate(midnight.getUTCDate() + 1);
        }

        let timeDiff = midnight - utc3Time;

        // Handle negative time by adding 1 day to the target time
        if (timeDiff < 0) {
            midnight.setUTCDate(midnight.getUTCDate() + 1); // Add one day to the target midnight
            timeDiff = midnight - utc3Time; // Recalculate the time difference
        }

        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;
    };

    useEffect(() => {
        // Set the initial value immediately when the component mounts
        setTimeRemaining(calculateTimeRemaining());

        // Set up the interval to update the time every second
        const interval = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(interval); // Clear the interval on component unmount
    }, []);


    return (
        <div className="timer-section">
            <TimerIcon className="timer-icon" />
            <span className="timer-text">{timeRemaining}</span>
            <span className="info-icon" onClick={openModal}>?</span>
        </div>
    );
};

export default DayTimer;
