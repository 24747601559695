import {
    SET_AUTH_USER, SET_CONSECUTIVE_DAYS, SET_LANGUAGE, SET_STATISTICS,
    SET_USER,
    SET_USER_TOKEN, SET_USER_TOTAL_POINTS
} from "./actionTypes";

const initialState = {
    userToken: null,
    id: null,
    locale: null,
    'tg-user-id': null,
    'consecutive-days': null,
    invite: null,
    username: null,
    'total_points': 0,
    "is-first-login": false,
    "is-first-login-today": false,
    statistics: null
    // id: null,
    // first_name: '',
    // last_name: '',
    // username: '',
    // language_code: '',
    // is_premium: false,
    // photo_url: '',
    // isAuthenticated: false,
    // total_points: 0,
    // last_visit: null,
    // streak: 0,
    // dailyTaskClaimed: false,
    // dailyTaskError: null,
    // userToken: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return { ...state, ...action.payload };
        case SET_AUTH_USER:
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
            };
        case SET_USER_TOKEN:
            return { ...state, userToken: action.payload };
        case SET_USER_TOTAL_POINTS:
            return {...state, total_points: action.payload}
        case SET_CONSECUTIVE_DAYS:
            return {
                ...state,
                "consecutive-days": action.payload
            }
        case SET_STATISTICS:
            return {
                ...state,
                statistics: action.payload
            }
        case SET_LANGUAGE:
            return { ...state, locale: action.payload };
        default:
            return state;
    }
};

export default userReducer;
