import {
    authUser,
    setCurrentUser,
    setUserToken
} from '../../entities/User/model/userActions';
import {getNews, getUserTasks} from '../../entities/Task/model/taskActions';
// import { setLanguage } from '../../entities/Language/model/languageActions';
import useTelegram from "../../shared/hooks/useTelegram";
import {getQuests} from "../../entities/Quest/model/questActions";
import {getUserReferrals} from "../../entities/Referral/model/referralActions";

export const initializeApp = (userData, invite) => async (dispatch) => {
    const {onClose, initDataUnsafe} = useTelegram();

    console.log("initDataUnsafe", initDataUnsafe);
    try {
        const userToken = await dispatch(authUser(userData, invite));
        if (userToken) {
            await dispatch(setUserToken(userToken));
            // await dispatch(setLanguage(initDataUnsafe.user.language_code));
            await dispatch(setCurrentUser());
            await dispatch(getUserTasks(0, 20));
            await dispatch(getUserReferrals(0, 20));
            await dispatch(getQuests());
            await dispatch(getNews(0, 20));
            // await dispatch(getStatistics());
        } else {
            console.error('Authentication failed, stopping further initialization.');
            onClose();
        }
    } catch (error) {
        console.error('Failed to initialize app:', error);
        onClose();
    }
};
