import React from "react";
import NewsList from "../../entities/Task/ui/NewsList";
import "./style.css";

const NewsPage = () => {
    return (
        <section className="news-page-container no-scrollbar">
            <NewsList />
        </section>
    );
}

export default NewsPage;


