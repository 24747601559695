import "./BottomNavigationBar.css";
import React, {useState} from "react";
import {translate} from "../../utils/translator";
import {FrensIcon, HomeIcon, NewsIcon, QuestIcon, TasksIcon} from "./HomeIcon";
import {
    selectDailyCheckCode,
    selectNewsContent,
    selectTasks
} from "../../../entities/Task/model/taskSelectors";
import {useSelector} from "react-redux";
import {selectQuestAnswer} from "../../../entities/Quest/model/questSelectors";

export default function BottomNavigationBar({goToPage}) {
    const [activeButton, setActiveButton] = useState('');
    const dailyCode = useSelector(selectDailyCheckCode);
    const tasks = useSelector(selectTasks);
    const questAnswer = useSelector(selectQuestAnswer);
    const news = useSelector(selectNewsContent);

    console.log("News: ", news);
    const buttonsData = [
        {
            title: translate("bottom_navigation_button_home"),
            icon: <HomeIcon active={activeButton === ''} />,
            notification: dailyCode.status === "DONE" ? 0 : 1,
            path: ''
        },
        {
            title: translate("bottom_navigation_button_tasks"),
            icon: <TasksIcon active={activeButton === 'Tasks'} />,
            notification: tasks ? tasks.filter(task => task.status !== "DONE").length : 0,
            path: 'Tasks'
        },
        {
            title: translate("bottom_navigation_button_quest"),
            icon: <QuestIcon active={activeButton === 'Quest'}/>,
            notification: questAnswer === 0 ? 0 : 1,
            path: 'Quest'
        },
        {
            title: translate("bottom_navigation_button_frens"),
            icon: <FrensIcon active={activeButton === 'Frens'}/>,
            notification: 0,
            path: 'Frens'
        },
        {
            title: translate("bottom_navigation_button_news"),
            icon: <NewsIcon active={activeButton === 'News'}/>,
            notification: news.filter(item => item["is-read"] === false).length,
            path: 'News'
        },
    ];

    const handleButtonClick = (path) => {
        setActiveButton(path);
        goToPage(path)
        // navigate(path);
    };

    return (
        <footer className="footer">
            {buttonsData.map((item, index) => (
                <div
                    className={`nav-item ${activeButton === item.path ? 'active' : ''}`}
                    onClick={() => handleButtonClick(item.path)}
                    key={index}
                >
                    <div className="icon-container">{item.icon}</div>
                    <span className="nav-text">{item.title}</span>
                    {
                        item.notification !== 0 &&
                            <span className="notification-badge">{item.notification}</span>
                    }
                    {/*{*/}
                    {/*    item.notification.length > 0 &&*/}
                    {/*    <span className="notification-badge">{item.notification.length}</span>*/}
                    {/*}*/}
                </div>
            ))}
        </footer>
    );
}
